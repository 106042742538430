// Dependencies
import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"

// Components
import { Navbar, Menu, ContactOverlay } from "gatsby-shared-ui"

// Helpers
import { BodyLockContextProvider } from "gatsby-shared-ui/components/contexts/BodyLockContext"
import { graphql, useStaticQuery } from "gatsby"
import { setEventListener } from "gatsby-shared-ui/utils/helpers"
import Logo from "../assets/Logo"
import palette from "../../palette"
import useLocationGlobal from "gatsby-shared-ui/hooks/useLocationGlobal"

const Layout = ({ children }) => {
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState()
  const [contactOpen, setContactOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState()
  const [menuContent, setMenuContent] = useState(null)
  const [contactContent, setContactContent] = useState(null)
  const [APIEndpoint, setAPIEndpoint] = useState(null)

  const locale = useLocationGlobal()

  useEffect(() => {
    // Runs only once
    setEventListener("contact-overlay", e => {
      setContactOpen(e.detail)
    })

    return () => {
      setEventListener("contact-overlay", () => {
        setContactOpen(false)
      })
    }
  }, [])

  const { menu, contact } = useStaticQuery(graphql`
    query MenuQuery {
      menu: craft {
        globalSets(site: ["mawayde", "mawayen", "mawayes"], handle: "menuContent") {
          ... on craft_menuContent_GlobalSet {
            language
            menuContent {
              ... on craft_menuContent_linkList_BlockType {
                links {
                  ... on craft_links_link_BlockType {
                    linkName
                    linkAddress {
                      url
                    }
                  }
                }
                plainText
              }
            }
          }
        }
      }
      contact: craft {
        globalSets(site: ["mawayde", "mawayen", "mawayes"], handle: "contactOverlay") {
          ... on craft_contactOverlay_GlobalSet {
            language
            address
            contactFormContent {
              ... on craft_contactFormContent_info_BlockType {
                headline
                redactor
                linkName
                linkAddress {
                  url
                }
                plainText
              }
              ... on craft_contactFormContent_formFields_BlockType {
                __typename
                contactFormField {
                  ... on craft_contactFormField_formField_BlockType {
                    __typename
                    fieldName
                    fieldSize
                    isRequired
                  }
                  ... on craft_contactFormField_dropdown_BlockType {
                    __typename
                    label
                    options
                    isRequired
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (location) {
      const currentMenuContent = menu.globalSets.find(menuItem => menuItem.language === locale)
      const currentContactContent = contact.globalSets.find(contactItem => contactItem.language === locale)

      setMenuContent(currentMenuContent.menuContent)
      setContactContent(currentContactContent.contactFormContent)
      setAPIEndpoint(currentContactContent.address)
    }
  }, [location])

  useEffect(() => {
    // Time animation offset
    if (isAnimating) {
      setTimeout(() => setIsAnimating(false), 1000)
    }
  }, [isAnimating])

  return (
    <BodyLockContextProvider>
      <main className="bg-background">
        <Navbar
          toggleMenu={() => {
            if (!isAnimating) setMenuOpen(!menuOpen)
            setIsAnimating(true)
          }}
          isOpen={menuOpen}
          isAnimating={isAnimating}
          Logo={Logo}
          root="mAway"
        />
        {menuContent && (
          <Menu
            isOpen={menuOpen}
            isAnimating={isAnimating}
            backgroundColor={palette.menu}
            toggleMenu={() => {
              if (!isAnimating) setMenuOpen(!menuOpen)
              setIsAnimating(true)
            }}
            content={menuContent}
          />
        )}
        {/* Contact */}
        {contactContent && (
          <div className="relative">
            <ContactOverlay
              root="mAway"
              className={"fixed z-50 transition ease-in-out duration-700 " + (contactOpen ? "translate-x-0" : "translate-x-full")}
              content={contactContent}
              open={contactOpen}
              endpoint={APIEndpoint}
            />
          </div>
        )}
        {children}
        {/* @todo: Add Footer */}
      </main>
    </BodyLockContextProvider>
  )
}

export default Layout
