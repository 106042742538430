import React from "react"
import PropTypes from "prop-types"

function Logo({ theme, forceLogoColor, isOpen, ...props }) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="156" height="31" fill="none" viewBox="0 0 156 31">
      <g clipPath="url(#clip0_300_24602)">
        <path
          fill={forceLogoColor ? forceLogoColor : theme === "light" || isOpen ? "#FF4619" : "#262626"}
          d="M0 22.43h8.107V10.625c0-2.292.655-3.903 2.747-3.903 2.457 0 2.658 1.848 2.658 3.902v11.808h8.107V10.623c0-2.29.655-3.902 2.747-3.902 2.457 0 2.658 1.848 2.658 3.902v11.808h8.107V7.477c0-3.529-1.84-7.393-7.288-7.393-5.279 0-6.916 3.192-7.244 3.399-.983-2.185-3.44-3.399-5.732-3.399-2.978 0-5.323.924-6.96 3.444h-.083V.626H0v21.805z"
        ></path>
        <path
          fill={forceLogoColor ? forceLogoColor : theme === "light" || isOpen ? "#FBFBFB" : "#262626"}
          d="M70.872 22.43a11.187 11.187 0 01-1.184-5.46V7.805c0-5.629-3.811-7.813-10.608-7.813-7.333 0-10.192 2.605-10.772 7.561l7.861.29c.127-1.764.908-2.352 2.583-2.352 1.921 0 2.665.764 2.665 1.932v1.528l-7.444 1.176c-5.04.764-6.633 3.23-6.633 6.423 0 4.284 3.156 6.683 7.735 6.683 3.193 0 5.404-1.138 6.796-3.407.11.892.361 1.759.745 2.566l8.256.039zm-12.894-4.498c-1.6 0-2.501-.626-2.501-1.886 0-1.138.744-1.764 2.62-2.1l3.603-.672v.962c0 2.772-1.846 3.696-3.722 3.696zM99.623.756l-2.584 9.363-.9 3.659-.82-3.743-2.396-9.28h-7.244l-2.233 9.494-.573 2.856-.61-2.902L79.67.756h-8.338l6.954 21.675h8.032l2.74-10.418.372-1.596.328 1.528 2.74 10.463h8.017L107.477.733l-7.854.023zm31.825 21.675a11.115 11.115 0 01-1.191-5.461V7.805c0-5.629-3.804-7.813-10.601-7.813-7.333 0-10.199 2.605-10.772 7.561l7.861.329c.127-1.765.901-2.353 2.583-2.353 1.921 0 2.658.764 2.658 1.933v1.527l-7.415 1.184c-5.04.764-6.633 3.23-6.633 6.423 0 4.284 3.149 6.683 7.735 6.683 3.194 0 5.405-1.139 6.797-3.407.118.895.382 1.762.782 2.566l8.196-.007zm-12.901-4.499c-1.593 0-2.494-.626-2.494-1.886 0-1.138.744-1.764 2.62-2.1l3.603-.672v.962c0 2.772-1.846 3.696-3.722 3.696h-.007zM147.372.756l-3.529 13.106-.819-4.453-2.538-8.653h-8.8l8.517 21.545-.209.55c-.364 1.046-1.019 1.466-2.33 1.466h-2.911V31h4.832c4.385 0 6.7-1.596 8.025-5.545l8.398-24.7h-8.636z"
        ></path>
        <path
          fill={forceLogoColor ? forceLogoColor : theme === "light" || isOpen ? "#FF4619" : "#262626"}
          d="M41.407 22.591c.72 0 1.423-.219 2.022-.629a3.716 3.716 0 001.34-1.676 3.824 3.824 0 00.208-2.158 3.766 3.766 0 00-.996-1.912 3.613 3.613 0 00-1.864-1.022 3.555 3.555 0 00-2.104.212 3.661 3.661 0 00-1.633 1.376 3.804 3.804 0 00-.614 2.075c0 .99.384 1.94 1.066 2.64a3.594 3.594 0 002.575 1.094z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_300_24602">
          <path fill="#fff" d="M0 0H156V31H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  forceLogoColor: PropTypes.string,
  isOpen: PropTypes.bool,
}

Logo.defaultProps = {
  color: "#FF4619",
}

export default Logo
